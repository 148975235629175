import React from 'react'
import { Helmet } from 'react-helmet'
import { graphql } from 'gatsby'
import type { AllMdx } from '../types'
import { ArticleSummaries } from '../components'
import { siteMetaData } from '../variables'

interface Props {
  readonly data: AllMdx
  readonly pageContext: {
    readonly tag: string
  }
}

export default function Page({
  data: {
    allMdx: { edges },
  },
  pageContext: { tag },
}: Props) {
  const title = `${tag} | ${siteMetaData.siteName}`
  return (
    <>
      <Helmet htmlAttributes={{ lang: 'ja' }}>
        <title>{title}</title>
        <meta name="description" content={siteMetaData.description} />
        <meta name="og:title" content={title} />
        <meta name="og:description" content={siteMetaData.description} />
        <meta name="og:site_name" content={siteMetaData.siteName} />
        <meta name="twitter:card" content="summary" />
      </Helmet>
      <ArticleSummaries articles={edges.map(({ node }) => node)} />
    </>
  )
}

export const query = graphql`
  query TagsQuery($tag: String) {
    allMdx(
      sort: { fields: frontmatter___publishedDate, order: DESC }
      filter: { frontmatter: { tags: { eq: $tag } } }
    ) {
      edges {
        node {
          id
          fields {
            path
          }
          frontmatter {
            title
            bodyPrefix
            description
            thumbnail
            publishedDate
            updatedDate
            tags
          }
        }
      }
    }
  }
`
